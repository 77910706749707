.about__wallpaper {
  background: url("../images/wallpaper_max_400-min.png") no-repeat;
  height: 632px;
  width: 100%;
  background-size: cover;
  color: var(--white-color);
}

.about__text-block div {
    margin-left: 15px;
    border-left: 3px solid white;
}

.about__title {
  text-transform: uppercase;
  font-size: var(--h1-size);
  line-height: 60px;
  padding: 103px 0 30px 20px;
}

.about__description {
  font-size: var(--h3-size);
  line-height: 25px;
  padding-left: 5px;
  max-width: 300px;
}

@media (min-width: 400px) {
  .about__wallpaper {
    background-image: url("../images/wallpaper_max_750-min.png");
    height: 632px;
  }

  .about__description {
    max-width: 350px;
  }
}

@media (min-width: 750px) {
  .about__wallpaper {
    background-image: url("../images/wallpaper_max_1100-min.png");
  }

  .about__text-block {
    padding-left: 65px;
  }

  .about__title {
    padding-top: 82px;
  }
}

@media (min-width: 1250px) {
  .about__wallpaper {
    background-image: url("../images/wallpaper_max_1920-min.png");
    height: 705px;
  }

  .about__title {
    font-size: var(--h1-size);
    line-height: 84px;
  }

  .about__description {
    font-size: var(--h3-size-l);
    line-height: 40px;
    max-width: 480px;
  }

  .about__text-block {
    padding-left: 121px;
  }
}