.about__slider {
  background: var(--black-color2);
}
.about__slider .slick-slider {
  height: 143px;
  margin-left: 20px;
}
.about__slider .slick-slider .nextArrow, .about__slider .slick-slider .prevArrow {
  background: var(--gray-color);
  position: absolute;
  width: 40px;
  height: 107px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.about__slider .slick-slider .prevArrow {
  top: 23px;
  left: 20px;
}
.about__slider .slick-slider .nextArrow {
  top: 23px;
  right: 20px;
}
.about__slider div.slick-slide {
  height: 96px;
  background: var(--gray-color);
  margin: 23px 15px;
}
.about__slider div span {
  padding: 32px 20px;
  text-align: center;
  outline: none;
}
.about__slider div div {
  font-size: var(--h4-size);
  color: var(--white-color);
  height: 100%;
}
.about__slider div div div {
  display: flex !important;
  justify-content: center;
  text-align: center;
}

@media (min-width: 1200px) {
  .about__slider .slick-slider {
    height: 153px;
    margin-left: 0;
  }
  .about__slider .slick-slider .slick-list {
    margin: 0 60px;
  }
  .about__slider div.slick-slide {
    height: 107px;
    margin: 23px 15px;
  }
  .about__slider div span {
    padding: 25px 20px;
    text-align: center;
  }
  .about__slider div div {
    font-size: var(--h4-size-m);
  }
}
@media (min-width: 1450px) {
  .about__slider .slick-slider {
    height: 174px;
    margin-left: 0;
  }
  .about__slider .slick-slider .nextArrow, .about__slider .slick-slider .prevArrow {
    background: var(--gray-color);
    position: absolute;
    width: 40px;
    height: 127px;
  }
  .about__slider div.slick-slide {
    height: 127px;
    margin: 23px 15px;
  }
  .about__slider div span {
    padding: 34px 20px;
    text-align: center;
  }
}
@media (min-width: 1550px) {
  .about__slider .slick-slider .nextArrow, .about__slider .slick-slider .prevArrow {
    width: 80px;
    height: 127px;
  }
  .about__slider .slick-slider .slick-list {
    margin: 0 100px;
  }
  .about__slider .slick-slider div div {
    font-size: var(--h4-size-l);
  }
}