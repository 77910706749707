#photo {
  background: var(--black-color2);
  color: var(--white-color);
  padding-top: 50px;
  position: relative;
}
#photo #photo-anchor {
  position: absolute;
  margin-top: -70px;
}
#photo .slick-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: auto;
  max-width: 350px;
}

.photo__title {
  font-size: var(--h2-size);
  text-align: center;
}

.slick-dots {
  bottom: -140px !important;
}

.slider {
  max-width: 90%;
  margin: 0 auto;
}

#photo .slick-slider {
  margin-top: 50px;
}
#photo .slick-slider .nextArrow, #photo .slick-slider .prevArrow {
  position: absolute;
  cursor: pointer;
  top: 246px;
  color: var(--white-color);
  z-index: 10;
}
#photo .slick-slider .prevArrow {
  left: 150px;
}
#photo .slick-slider .nextArrow {
  right: 150px;
}
#photo .slick-slider .slick-dots li {
  margin: 0 5px;
  width: 40px;
  height: 40px;
  display: inline-block;
  background: var(--black-color2);
}
#photo .slick-slider .slick-dots li div {
  height: 100%;
  padding-top: 12px;
}
#photo .slick-slider .slick-dots li.slick-active {
  background: var(--red-color);
}
#photo .slick-slide div {
  text-align: center;
  outline: none;
}

.photo__slide-wrapper {
  width: 90% !important;
  margin-bottom: 10px;
}

.photo__slide {
  background-size: contain !important;
}

@media (min-width: 750px) {
  .slick-dots {
    background: var(--gray-color);
  }
  #photo {
    background: var(--gray-color);
    padding-bottom: 20px;
  }
  #photo .slick-slider .nextArrow, #photo .slick-slider .prevArrow {
    top: 246px;
  }
  #photo .slick-slider .prevArrow {
    left: 140px;
  }
  #photo .slick-slider .nextArrow {
    right: 140px;
  }
}
@media (min-width: 1000px) {
  #photo {
    background: var(--gray-color);
    padding-bottom: 109px;
  }
  #photo .slick-slider .slick-dots {
    bottom: -60px !important;
  }
  .slick-dots {
    bottom: -140px !important;
  }
}