#repair {
  color: var(--white-color);
  text-align: center;
  position: relative;
}
#repair #repair-anchor {
  position: absolute;
  margin-top: -70px;
}

.repair__item {
  background: var(--gray-color);
  width: 100%;
  max-width: 280px;
  height: 200px;
  padding: 27px;
  margin-top: 14px;
  box-sizing: border-box;
  cursor: pointer;
}

.repair__title {
  text-transform: uppercase;
  line-height: 30px;
  font-size: var(--h2-size);
  text-align: center;
  background: var(--black-color2);
}

.repairs__wrapper {
  padding-top: 50px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background: var(--black-color2);
}

.repair__item-title {
  line-height: 18px;
  padding: 15px 10px 0;
  font-size: var(--p1-size);
}

.repair__item-text {
  display: none;
  padding: 0 10px;
}

@media (min-width: 450px) {
  .repair__item {
    max-width: 280px;
    height: 210px;
  }
  .repairs__wrapper {
    justify-content: space-evenly;
  }
}
@media (min-width: 500px) {
  .repair__item {
    max-width: 245px;
    height: 210px;
  }
}
@media (min-width: 730px) {
  .repair__item {
    background: transparent;
  }
}
@media (min-width: 1000px) {
  #repair {
    padding-bottom: 25px;
  }
  .repair__item {
    max-width: 25%;
  }
  .repairs__wrapper {
    justify-content: center;
  }
}
@media (min-width: 1200px) {
  #repair {
    padding-top: 103px;
    background: var(--black-color2);
  }
  .repairs__wrapper {
    justify-content: center;
  }
  .repair__item-text {
    line-height: 22px;
  }
}
@media (min-width: 1300px) {
  .repair__item {
    max-width: 21%;
    width: 332px;
    height: 332px;
  }
  .repair__item-text {
    font-size: var(--p1-size);
    line-height: 32px;
  }
  .repair__item-title {
    font-size: var(--h3-size-l);
    line-height: 32px;
  }
}
@media (min-width: 1600px) {
  .repair__item {
    max-width: 25%;
    margin: 0 2%;
  }
}