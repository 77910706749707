#price {
  background: var(--black-color3);
}
#price ul {
  padding: 10px 0 0 0;
  margin: 0;
}
#price li {
  color: var(--white-color);
  padding: 10px 15px 10px 25px;
  font-size: var(--p3-size);
  line-height: 20px;
}

.price__header-description, .price__header-price {
  font-weight: 600;
  font-size: var(--p1-size);
  line-height: 25px;
  color: var(--white-color);
  background: var(--gray-color);
}

.price__header-wrapper {
  padding: 80px 0 40px;
  background: var(--black-color3);
}

.price__header {
  background: var(--gray-color);
  padding: 23px 10px;
  margin: 0 15px;
  display: flex;
  justify-content: space-between;
}

.price__header-price {
  display: none;
}

.price__service-price {
  padding: 5px 0 5px 20px;
  margin-top: 5px;
  background: rgba(34, 35, 35, 0.231372549);
  font-style: italic;
}

@media (min-width: 500px) {
  .price__header {
    box-sizing: border-box;
  }
  .price__header-price {
    display: block;
  }
  #price li {
    display: flex;
    width: 100%;
    box-sizing: border-box;
  }
  #price li:hover {
    background: rgba(0, 0, 0, 0.6);
    cursor: pointer;
  }
  .price__service-description-wrapper, .price__header-description {
    width: 70%;
    line-height: 20px;
  }
  .price__service-price {
    width: 30%;
    margin-top: 0;
    background: transparent;
  }
  .price__header-price {
    width: 30%;
  }
  .price__header-description, .price__header-price {
    padding-left: 10px;
  }
}
@media (min-width: 700px) {
  .price__header {
    margin: 0 20px;
  }
  #price li {
    padding-left: 30px;
  }
  .price__header-description, .price__header-price {
    font-size: var(--h4-size-l);
  }
  .price__service-description, .price__service-price {
    font-size: var(--p2-size);
    line-height: 25px;
  }
}
@media (min-width: 768px) {
  .price__header-wrapper {
    padding: 40px 0 40px;
  }
  .price__header-description, .price__service-description-wrapper {
    width: 80%;
  }
  .price__header-price, .price__service-price {
    width: 20%;
  }
  .price__service-description {
    width: 90%;
  }
}
@media (min-width: 1000px) {
  .price__header {
    margin: 0 40px;
  }
  #price li {
    padding-left: 50px;
  }
  .price__header-description {
    padding-left: 30px;
  }
  .price__header-price {
    padding-left: 120px;
  }
  .price__service-description {
    padding-left: 20px;
    line-height: 25px;
  }
}
@media (min-width: 1200px) {
  .price__service-description, .price__service-price {
    font-size: var(--h4-size-l);
    line-height: 30px;
  }
}