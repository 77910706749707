:root {
    --white-color: #FFFFFF;
    --white-color-07: rgba(255, 255, 255, 0.7);
    --white-color-05: rgba(255, 255, 255, 0.5);
    --black-color: #000000;
    --black-color2: #111111;
    --black-color3: #00000045;
    --gray-color: #222323;
    --red-color: #CF2923;

    --h1-size: 50px;
    --h2-size: 25px;
    --h3-size: 18px;
    --h4-size: 14px;

    --h4-size-m: 16px;

    --h1-size-l: 70px;
    --h2-size-l: 35px;
    --h3-size-l: 25px;
    --h4-size-l: 20px;

    --p1-size: 18px;
    --p2-size: 16px;
    --p3-size: 14px;
}
