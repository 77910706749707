#guaranties {
  padding: 60px 40px 100px;
  background: var(--black-color2);
}

.guaranties__title {
  text-transform: uppercase;
  font-size: var(--h2-size);
  line-height: 30px;
  color: var(--white-color);
}

.guaranties__text {
  color: var(--white-color-07);
  padding-top: 30px;
}

.guaranties__text-get-more {
  color: var(--red-color);
  text-decoration-line: underline;
  padding-top: 30px;
}

.guaranties__text, .guaranties__text-get-more {
  font-size: var(--p1-size);
  line-height: 32px;
}

.guaranties__text-long {
  display: none;
}

.guaranties__text-wrapper {
  text-align: center;
  width: 100%;
  margin: 0 auto;
}

.guaranties__text-get-more {
  cursor: pointer;
}

@media (min-width: 600px) {
  .guaranties__text-wrapper {
    text-align: center;
    width: 80%;
    margin: 0 auto;
  }
}

@media (min-width: 1200px) {
  #guaranties {
    position: relative;
    background: url("../images/wallpaper_guaranties_1200-min.png") no-repeat;
    height: 575px;
    padding: 0;
    margin-top: 50px;
  }

  .guaranties__text-wrapper {
    position: absolute;
    width: 54%;
    padding: 20px 20px;
    background: var(--gray-color);
    box-sizing: border-box;
    top: 50px;
    right: 55px;
    text-align: inherit;
  }

  .guaranties__text-long {
    display: block;
  }

  .guaranties__text-short, .guaranties__text-get-more {
    display: none;
  }
}

@media (min-width: 1300px) {
  .guaranties__text-wrapper {
    width: 55%;
    padding: 40px 45px;
    top: 50px;
    right: 55px;
  }
}

@media (min-width: 1400px) {
  #guaranties {
    background: url("../images/wallpaper_guaranties_1400-min.png") no-repeat;
    height: 655px;
  }

  .guaranties__text-wrapper {
    width: 55%;
    padding: 40px 45px;
    top: 50px;
    right: 55px;
  }

  .guaranties__text {
    font-size: 20px;
    line-height: 35px;
  }
}

@media (min-width: 1450px) {
  .guaranties__text-wrapper {
    padding: 54px 60px;
    right: 72px;
  }
}

@media (min-width: 1600px) {
  #guaranties {
    background: url("../images/wallpaper_guaranties_1600-min.png") no-repeat;
    height: 740px;
  }

  .guaranties__text-wrapper {
    padding: 54px 109px;
    right: 120px;
    top: 35px;
    width: 50%;
  }
}

@media (min-width: 1700px) {
  #guaranties {
    background: url("../images/wallpaper_guaranties-min.png") no-repeat;
    height: 825px;
  }

  .guaranties__text-wrapper {
    top: 110px;
  }
}