#service {
  background: var(--gray-color);
  font-size: var(--p2-size);
  line-height: 25px;
  color: var(--white-color);
}

.service__title, .service__item {
  position: relative;
  line-height: 25px;
}

.service__title {
  padding: 20px 20px 0 43px;
}

.service__item {
  color: var(--white-color-07);
  margin: 50px 20px;
  padding-left: 23px;
  width: 80%;
}
.service__item .dot-icon {
  width: 8px;
  height: 8px;
  background: #E2242E;
  position: absolute;
  border-radius: 50%;
  left: 8px;
  top: 8px;
}

.service__item:last-child {
  padding-bottom: 50px;
  margin-bottom: 0;
}

@media (min-width: 700px) {
  #service {
    font-size: var(--p1-size);
  }
  .service__title {
    text-align: center;
    font-size: 22px;
    padding: 20px 20% 20px;
  }
  .service__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .service__item {
    width: 40%;
    margin: 25px 20px;
  }
}
@media (min-width: 1100px) {
  .service__title {
    text-align: center;
    padding: 40px 20% 40px;
  }
  .service__item {
    width: 25%;
    margin: 25px 20px;
  }
}
@media (min-width: 1200px) {
  .service__item {
    font-size: 20px;
  }
}
@media (min-width: 1400px) {
  .service__title {
    text-align: center;
    padding: 40px 32% 40px;
  }
  .service__item {
    font-size: 20px;
  }
}