.header__icon-phone {
  position: fixed;
  top: 8px;
  z-index: 3;
  right: 20px;
}

#menuToggle
{
  display: block;
  background-color: var(--white-color);
  height: 28px;
  position: fixed;
  padding: 18px 0 10px 20px;
  width: 100%;


  z-index: 1;

  -webkit-user-select: none;
  user-select: none;
}

#menuToggle a
{
  text-decoration: none;
  color: var(--white-color);

  transition: color 0.3s ease;
  display: block;
  width: 100%;
}

#menuToggle a:hover
{
  color: var(--red-color);
}


#menuToggle input
{
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: 6px;
  left: 13px;

  cursor: pointer;

  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */

  -webkit-touch-callout: none;
}

#menuToggle span
{
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;

  background: var(--black-color);
  border-radius: 3px;

  z-index: 1;

  transform-origin: 4px 0px;

  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
  background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
  opacity 0.55s ease;
}

#menuToggle span:first-child
{
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2)
{
  margin-bottom: 0;
  transform-origin: 0% 100%;
}

/*
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#menuToggle input:checked ~ span
{
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #232323;
}

/*
 * But let's hide the middle one.
 */
#menuToggle input:checked ~ span:nth-last-child(3)
{
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
#menuToggle input:checked ~ span:nth-last-child(2)
{
  transform: rotate(-45deg) translate(0, -1px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
#menu
{
  height: calc(100vh - 55px);
  position: absolute;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: -50px;
  text-align: center;

  background: var(--black-color);
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */

  transform-origin: 0% 0%;
  transform: translate(-100%, 0);

  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

.menu-wrapper {
  height: 75%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

#menu li
{
  margin-bottom: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
}

/*
 * And let's slide it in from the left
 */
#menuToggle input:checked ~ ul
{
  margin-bottom: 0;
  transform: none;
}

header {
  background: var(--white-color);
}

.header__phone {
  display: none;
  text-decoration: none;
}

#menu__desktop,
.menu__desktop-long,
.menu__desktop-short,
.header__logo {
  display: none;
}

.header__logo-mobile {
  height: 55px;
  position: fixed;
  left: 55px;
  z-index: 20;
}

.header__icon-phone-wrapper {
  color: transparent;
  font-size: 0;
}

@media (min-width: 768px) {
  #menuToggle {
    display: none;
  }

  .header__logo-mobile {
    z-index: -5;
    position: absolute;
  }

    header {
    display: flex;
  }

  .header__icon-phone {
    top: 16px;
    right: 25px;
    position: absolute;
  }

  .header__navigation {
    align-self: center;
    width: 100%;
  }

  .header__phone {
    padding: 12px 20px;
    background-color: var(--red-color);
  }

  a.header__phone {
    align-self: center;
    color: var(--white-color);
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    position: absolute;
    top: 16px;
    right: 25px;
  }

  #menu__desktop {
    display: flex;
    padding: 0;
  }

  .menu__desktop-short {
    display: block;
  }

  #menu__desktop li {
    list-style-type: none;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
  }

  #menu__desktop a {
    margin-left: 3%;
    text-decoration: none;
    color: var(--black-color);
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
  }

  .header__logo {
    display: block;
    height: 80px;
  }
}

@media (min-width: 900px) {
  .header__icon-phone {
    display: none;
  }

  .header__phone {
    display: block;
  }
}

@media (min-width: 1000px) {
  #menu__desktop li {
    font-size: 20px;
  }
}

@media (min-width: 1200px) {
  .menu__desktop-short {
    display: none;
  }

  .menu__desktop-long {
    display: block;
  }

  #menu__desktop li {
    font-size: 16px;
  }

  .header__logo {
    margin-left: 5%;
  }

  a.header__phone {
    right: 3%;
  }
}

@media (min-width: 1300px) {
  #menu__desktop li {
    font-size: 18px;
  }

  a.header__phone {
    font-size: 20px;
  }
}

@media (min-width: 1400px) {
  .header__navigation {
    padding-left: 3%;
  }

  a.header__phone {
    right: 6%;
  }

  svg.header__logo {
    margin-left: 20%;
  }
}


@media (min-width: 1500px) {
  #menu__desktop a {
    margin-left: 2%;
  }

  svg.header__logo {
    margin-left: 40%;
  }

  .header__navigation {
    padding-left: 10%;
  }
}

@media (min-width: 1700px) {

  .header__navigation {
    padding-left: 15%;
  }
}

