h1, h2, h3, h4, p {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  background: #111111;
}

.hidden {
  color: white;
  position: absolute;
}