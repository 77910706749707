#contact {
  padding-top: 10px;
  background: var(--black-color2);
}

.contact__title {
  font-size: var(--h2-size);
  color: var(--white-color);
  line-height: 30px;
}

.contact__about-us {
  margin: 0 auto;
  display: none;
}

.contact__contacts {
  margin: 60px auto 110px;
}

.contact__schedule-wrapper {
  background: var(--red-color);
}

.contact__schedule {
  margin: 0 auto;
  background: var(--red-color);
  padding: 84px 0;
}

.contact__schedule-title {
  font-size: var(--p1-size);
  line-height: 32px;
  color: var(--white-color-05);
}

.contact__schedule-text {
  font-weight: 600;
  font-size: var(--p1-size);
  line-height: 25px;
  color: var(--white-color);
}

.contact__contacts,
.contact__schedule,
.contact__about-us {
  width: 60%;
}

ul.footer-menu {
  margin: 0;
  padding: 0;
}

.footer-menu {
  color: var(--white-color);
  font-size: var(--p1-size);
  line-height: 32px;
  padding: 0;
}
.footer-menu li {
  list-style: none;
  padding-top: 15px;
}
.footer-menu a {
  color: var(--white-color-05);
  text-decoration: none;
}

.contact__contacts-body {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;
}

.contact__title-address,
.contact__title-telephone {
  font-size: var(--h3-size);
  line-height: 25px;
  font-weight: 600;
  color: var(--white-color);
}

.contact__title-address-icon {
  margin-left: 35px;
  cursor: pointer;
}

.contact-description, .contact__telephone {
  width: 100%;
  padding-top: 10px;
  color: var(--white-color-05);
  line-height: 32px;
  font-size: var(--h3-size);
}

.contact__working-days, .contact__email {
  padding-top: 40px;
}

.contact__day-off {
  padding-top: 10px;
}

@media (min-width: 500px) {
  #contact {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-top: 70px;
  }
  .contact__contacts,
  .contact__schedule,
  .contact__about-us {
    width: 40%;
    margin: 0;
    padding: 0 0 35px 0;
    display: block;
  }
  .contact__contacts-body {
    margin-top: 16px;
  }
  .contact-description {
    width: 100%;
  }
  .contact__schedule, .contact__schedule-wrapper {
    width: 100%;
  }
  .contact__schedule-wrapper {
    padding-top: 20px;
  }
  .contact__schedule {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .contact__title, .contact__email {
    width: 100%;
  }
  .contact__email {
    padding-top: 20px;
  }
  .contact__working-days {
    padding-top: 10px;
    padding-right: 10px;
  }
  .contact__day-off {
    padding-left: 10px;
  }
  .contact__email {
    padding-top: 10px;
  }
  .contact__email .contact__schedule-text {
    display: none;
  }
}
@media (min-width: 768px) {
  .contact__email {
    padding-top: 20px;
  }
  .contact__email .contact__schedule-text {
    display: block;
  }
  #contact {
    padding-top: 0;
    justify-content: space-between;
  }
  .contact__contacts,
  .contact__about-us,
  .contact__schedule-wrapper {
    width: 25%;
  }
  .contact__contacts,
  .contact__about-us,
  .contact__schedule-wrapper {
    padding-top: 70px;
    padding-left: 25px;
    padding-right: 25px;
  }
  .contact__schedule {
    text-align: inherit;
    justify-content: flex-start;
  }
  .contact__day-off {
    padding-left: 0;
  }
}
@media (min-width: 1000px) {
  #contact {
    justify-content: space-between;
  }
  .contact__contacts,
  .contact__about-us,
  .contact__schedule-wrapper {
    width: 25%;
  }
  .contact__contacts,
  .contact__about-us,
  .contact__schedule-wrapper {
    padding-top: 70px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .contact-description {
    height: 185px;
  }
  .contact__email {
    padding-top: 120px;
  }
  .contact__working-days {
    width: 100%;
    padding-top: 16px;
  }
}
@media (min-width: 1100px) {
  .contact__contacts,
  .contact__about-us,
  .contact__schedule-wrapper {
    width: 25%;
  }
  .contact__contacts,
  .contact__about-us,
  .contact__schedule-wrapper {
    padding-top: 80px;
    padding-left: 35px;
    padding-right: 35px;
  }
  .contact-description {
    height: 165px;
  }
  .contact__email {
    padding-top: 100px;
  }
}
@media (min-width: 1300px) {
  .contact__contacts, .contact__about-us, .contact__schedule-wrapper {
    padding-top: 90px;
    padding-left: 45px;
    padding-right: 45px;
  }
  .contact-description {
    width: 80%;
  }
}
@media (min-width: 1350px) {
  .contact__contacts, .contact__about-us, .contact__schedule-wrapper {
    padding-top: 90px;
    padding-left: 55px;
    padding-right: 55px;
  }
}
@media (min-width: 1450px) {
  .contact-description {
    width: 75%;
  }
}
@media (min-width: 1600px) {
  .contact-description {
    height: 155px;
  }
  .contact__email {
    padding-top: 90px;
  }
}
.map__button-wrapper {
  text-align: right;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay--after-open {
  background: transparent !important;
}